import React from 'react'
import Mini from '../components/miniSect';
import { StaticQuery, graphql, Link } from "gatsby";

class SubProduct extends React.Component{
    render(){
        let products = this.props.products;
        let subproduct = products.map((item) => {
            if(item.node.frontmatter.belongsTo == this.props.subject)
            return <Link to={item.node.frontmatter.path}><Mini title={item.node.frontmatter.title} subtitle={item.node.frontmatter.subtitle} image={item.node.frontmatter.image} /> </Link>
        });
        return(
            <div className="subproducts">
                {subproduct}
            </div>
        );
    }
}
class QueryLike extends React.Component{
    render(){
        return(
            <StaticQuery
    query={graphql`
    query{
    allMarkdownRemark{
        edges{
            node {
                frontmatter {
                    path
                    title
                    subtitle
                    belongsTo
                    image
                    descriptionTitle
                    description
                }
            }
        }
    }
      }
    `}
    render={data => <SubProduct subject={this.props.subject} products={data.allMarkdownRemark.edges} />}
  />
        );
    }
}
export default QueryLike