import React from 'react'



class Mini extends React.Component{
    render(){
        return(
            <div className="miniSection">
                <img className="miniSectionImage" src={this.props.image} />
                <div className="miniSectionDiv">
                <p className="miniSectionTitle">{this.props.title}</p>
                <p className="miniSectionSubtitle">{this.props.subtitle}</p>
                </div>
                
            </div>
        );
    }
}
export default Mini